<template>
  <v-container
    class="pa-0 mt-1 d-flex align-start"
    :style="{ maxWidth: maxWidth + 'px' }"
  >
    <v-card
      class="rounded-lg"
      width="100%"
      :height="$vuetify.breakpoint.height - 100 + 'px'"
    >
      <v-card-title
        class="primary white--text px-1 pb-5 pt-1"
      >
          <v-btn icon dark to="/app/session">
            <v-icon size="22px">mdi-arrow-left</v-icon>
          </v-btn>
          <span class="ml-2 subtitle-1 text-truncate" style="max-width:94%">{{ session_data.title }}</span>
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" dark>
                <v-icon size="22px">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ session_data.description }}</span>
          </v-tooltip>
      </v-card-title>
      <v-card-text class="white rounded-lg card-relative">
        <div class="automatic_action_notification" v-if="showAutomaticNotification">
          {{ automaticNotificationMessage }}
        </div>
        <v-row v-if="!session_upcoming">
          <v-col cols="12" md="8">
            <div style="position: absolute;top: 45%;left: 30%;z-index:1">
              <v-btn  v-if="show_playingbutton" color="success" fab  x-large @click="playVideo">
                <v-icon>mdi-play-circle-outline</v-icon>
              </v-btn>
            </div>
            <video width="100%" height="100%" autoplay v-if="!session_data.is_live_meeting"
            :src="session_data.meeting_id_url+'#t='+startTime"
            ref="player"
            :class="[session_data && isCompleted(session_data) ? '' : 'disable_click']"
            :controls="session_data && isCompleted(session_data) && !show_playingbutton"
            >
              Your browser does not support the video tag.
            </video>
            <iframe
              width="100%"
              height="100%"
              :src="base_url + session_data.meeting_id_url"
              class="rounded-lg"
              allow="camera; microphone"
              allowfullscreen
              v-else
            ></iframe>
            <span v-if="session_data.is_live_meeting" class="text-body-2 primary--text"> <v-icon>mdi-information-outline</v-icon> Please click the Full Screen icon to view Panelist's Camera with Shared Screen</span>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat class="mx-auto">
              <v-card-title class="pa-0">
                <v-btn
                  block
                  color="primary"
                  class="text-capitalize"
                  to="/app/info-desk"
                >
                  Help
                  <span class="mdi mdi-help-circle-outline text-h6"></span>
                </v-btn>
              </v-card-title>
              <v-card-text
                class="chat-screen d-flex flex-column-reverse grey lighten-4 pa-0"
              >
                <v-card
                  v-for="message in session_message"
                  :key="message.message_id"
                  flat
                  class="grey lighten-4"
                >
                  <v-list-item
                    :key="message.message_id"
                    v-if="message.attendee_id != myself.attendee_id"
                    class=""
                  >
                    <v-list-item-avatar
                      class="align-self-start mr-2 elevation-1"
                    >
                      <v-avatar size="40">
                        <v-img
                          :src="
                            message.attendee_data
                              ? message.attendee_data.profile_pic
                              : FRONT_ASSETS + 'placeholder.png'
                          "
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="received-message">
                      <v-card color="success" class="flex-none">
                        <v-badge
                          bordered
                          color="orange"
                          icon="mdi-crown-outline"
                          overlap
                          title="Speaker / Panelist"
                           v-if="!message.add_by_attendee"
                        >
                        <v-card-text
                          class="white--text pa-2 d-flex flex-column"
                        >
                          <span class="text-caption"
                            >{{ message.attendee_data ? message.attendee_data.first_name : "Deleted User" }}
                          </span>
                          <span class="align-self-start text-subtitle-1">{{
                            message.message
                          }}</span>
                          <span
                            class="text-caption font-italic align-self-end"
                            >{{ getTime(message.created_at) }}</span
                          >
                        </v-card-text>
                        </v-badge>
                         <v-card-text
                          class="white--text pa-2 d-flex flex-column" v-else
                        >
                          <span class="text-caption"
                            >{{ message.attendee_data ? message.attendee_data.first_name : "Deleted User" }}
                          </span>
                          <span class="align-self-start text-subtitle-1">{{
                            message.message
                          }}</span>
                          <span
                            class="text-caption font-italic align-self-end"
                            >{{ getTime(message.created_at) }}</span
                          >
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else :key="message.message_id">
                    <v-list-item-content class="sent-message justify-end">
                      <v-card color="primary" class="flex-none">
                        <v-badge
                          bordered
                          color="orange"
                          icon="mdi-crown-outline"
                          overlap
                          left
                          title="Speaker / Panelist"
                           v-if="!message.add_by_attendee"
                        >
                        <v-card-text
                          class="white--text pa-2 d-flex flex-column"
                        >
                          <span class="text-subtitle-1 chat-message">{{
                            message.message
                          }}</span>
                          <span class="text-caption font-italic align-self-start">
                            {{ getTime(message.created_at) }}
                          </span>
                        </v-card-text>
                        </v-badge>
                        <v-card-text
                          class="white--text pa-2 d-flex flex-column" v-else
                        >
                          <span class="text-subtitle-1 chat-message">{{
                            message.message
                          }}</span>
                          <span class="text-caption font-italic align-self-start">
                            {{ getTime(message.created_at) }}
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                    <v-list-item-avatar
                      class="align-self-start ml-2 elevation-1"
                    >
                      <v-img :src="message.attendee_data.profile_pic"></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-card-text>
              <v-card-actions class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      placeholder="Type your message"
                      rows="2"
                      v-model="new_message"
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="sendMessage()"
                      @keydown.enter.shift.exact="newlineMessage"
                      :loading="send_loading"
                      hide-details=""
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <i :class="{ 'red--text': messageLength > 400 }"
                      >Charcter left : {{ 400 - messageLength }}</i
                    >
                    <v-spacer></v-spacer>
                    <b>Participants joined : {{ activeUsers }}</b>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="d-flex flex-column justify-center align-center" :style="{ height:$vuetify.breakpoint.height - 200 + 'px' }">
          <p style="font-size: 20px;">Thanks for joining the session</p>
          <p class="text-center mb-2" style="font-size: 20px;">{{ session_data.title }}</p>
          <div class="clearfix w-100"></div>
          <span class="mt-6" style="font-size: 24px; color: #80bc40;">Starts In {{remaining_time}}</span>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showUpcomingSessionDialog" max-width="350" v-if="upcomingSession">
      <v-card>
        <v-card-text
          class="text-justify session_message_popup_text pt-3 pb-2"
        >
          The <b>{{ upcomingSession.title }}</b> session {{ isInBetween(upcomingSession) ? " is currently active." : "will begin shortly." }} Do you want to participate in the session ?
        </v-card-text>
        <!-- <v-divider /> -->
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            dark
            text
            tabindex="2"
            @click="closeUpcomingSessionDialog()"
          >
            Not Now
          </v-btn>
          <v-btn
            color="light-green"
            dark
            depressed
            tabindex="1"
            @click="handleJoinUpcomingSessionBtnClick()"
          >
            Join
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapActions } from "vuex";

function getIntitalData() {
  const moment = require("moment");

  return {
    session_data: [],
    session_message: [],
    new_message: "",
    send_loading: false,
    socket_messages: [],
    activeUsers: 0,
    subscription: null,
    show_playingbutton : false,
    startTime : 0,
    time: moment().format('YYYY-MM-DD'),
    session_upcoming:true,
    remaining_time : '',
    loadingSessionsList: false,
    sessions: [],
    upcomingSessionTimeoutId: null,
    upcomingSession: null,
    showUpcomingSessionDialog: false,
    showAutomaticNotification: false,
    automaticNotificationMessage: "",
    automaticNotificationTimeoutId: null
  };
}

export default {
  name: "SessionList",
  components: {},
  data() {
    return getIntitalData();
  },
  watch: {
    '$route.params': {
      handler(newParams, oldParams) {
        let newSessionId = newParams.id;
        let oldSessionId = oldParams ? oldParams.id : null;

        if (newSessionId && oldSessionId && newSessionId != oldSessionId) {
          this.upcomingSessionTimeoutId ? clearTimeout(this.upcomingSessionTimeoutId) : null;
          this.automaticNotificationTimeoutId ? clearTimeout(this.automaticNotificationTimeoutId) : null;
          this.upcomingSessionTimeoutId = null;
          this.removeSession();
          Object.assign(this.$data, getIntitalData());
          this.unsubscribeFromChannels(oldSessionId);
          this.getSessionDetails();
          this.getSessionMessages();
          this.subscribeToChannels(newSessionId);
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState("utils", ["maxWidth"]),
    base_url() {
      return (
        axios.defaults.baseURL +
        "zoom-meeting?token=" +
        this.myself.attendee_token +
        "&meetingNumber="
      );
    },
    myself() {
      return JSON.parse(localStorage.getItem("pinnacle_user"));
    },
    newMessageWithoutSpace() {
      return this.new_message.trim();
    },
    messageLength() {
      return this.newMessageWithoutSpace.length;
    },
    reverse_messages() {
      let messages = this.session_message;
      let msgArray = [];
      for (let i = messages.length; i--; ) {
        msgArray.push(messages[i]);
      }
      return msgArray;
    }
  },
  methods: {
    ...mapActions("active_session", ["setSession", "removeSession"]),
    playVideo(){
      this.show_playingbutton = false
      this.$refs['player'].play()
    },
    getYoutubeUrl(video_id) {
      return (
        "https://www.youtube.com/embed/" +
        video_id +
        "?autoplay=1&controls=0&disablekb=0&loop=1&playlist=" +
        video_id
      );
    },
    getTime(time) {
      let msg_date = this.moment(time);
      let today = this.moment(new Date());
      if (msg_date.isSame(today, "day")) {
        return this.moment(time).format("hh:mm A");
      } else {
        return msg_date.format("DD, MMM hh:mm A");
      }
    },
    newlineMessage() {
      this.new_message = `${this.new_message}`;
    },
    setSocketMessage(data) {
      this.session_message.unshift(data);
    },
    setActiveUsers(data) {
      this.activeUsers = data;
    },
    sendMessage() {
      if (this.newMessageWithoutSpace.length && this.messageLength <= 400) {
        this.send_loading = true;
        let data = {
          message: this.newMessageWithoutSpace,
          session_id: this.$route.params.id,
        };
        axios
          .post("/session/add_message", data)
          .then(() => {
            this.new_message = "";
            // this.session_message.unshift(data.data.data)
          })
          .then(() => {
            this.send_loading = false;
          });
      }
    },
    async getSessionsList() {
      this.loadingSessionsList = true;

      await axios
        .get("/session/list")
        .then((res) => {
          if (res.data.data) {
            this.sessions = res.data.data;
          }
        })
        .finally(() => {
          this.loadingSessionsList = false;
        })
    },
    getUpcomingSession() {
      const currentTime = this.moment();
      let upcomingSession = null;
      let upcomingSessionTimeDiffInSeconds = -1;
      const currentSessionEndTime = this.moment(this.session_data.end_time);

      for (let i = 0; i < this.sessions.length; i++) {
        const session = this.sessions[i];

        if (session.session_id != this.session_data.session_id) {
          if (this.isInBetween(session) && sessionStartTime.isAfter(currentSessionEndTime)) {
            console.log("in between upcoming session");
            upcomingSession = session;
            break;
          }

          const sessionStartTime = this.moment(session.start_time);
          const startTimeDiffInSeconds = sessionStartTime.diff(currentTime, 'seconds');

          if (startTimeDiffInSeconds >= 0 && sessionStartTime.isAfter(currentSessionEndTime)) {
            if (upcomingSessionTimeDiffInSeconds === -1 || startTimeDiffInSeconds < upcomingSessionTimeDiffInSeconds) {
              console.log("in before upcoming session");
              upcomingSession = session;
              upcomingSessionTimeDiffInSeconds = startTimeDiffInSeconds;
            }
          }
        }
      }

      return upcomingSession;
    },
    getSessionDetails() {
      axios
        .get("/session/details", {
          params: {
            session_id: this.$route.params.id,
            time : new Date().toString()
          },
        })
        .then(async (data) => {
          this.session_data = data.data.data;
          if (this.isUpcoming(this.session_data)) {
            this.session_upcoming = true
            this.setRemaining()
          } else {
            this.session_upcoming = false
          }
           let startTime = this.moment(this.session_data.start_time);
           let currentTime = this.moment();
           this.startTime = currentTime.diff(startTime, "seconds")

           if (this.isCompleted(this.session_data)) {
            this.startTime=0;
           }

          //  getting sessions list
          await this.getSessionsList();

          const currentSessionEndTime = this.moment(this.session_data.end_time);
          const upcomingSession = this.getUpcomingSession();

          if (upcomingSession) {
            console.log("upcoming session", upcomingSession);

            if (this.isUpcoming(this.session_data) || this.isInBetween(this.session_data)) {
              let timeDiffInSeconds = currentSessionEndTime.diff(currentTime, 'seconds');

              console.log("timeDiffInSeconds:", timeDiffInSeconds);

              if (timeDiffInSeconds > 0) {
                timeDiffInSeconds += 5; // to accomodate minor seconds change
                // set next session after current one completes
                this.upcomingSessionTimeoutId = setTimeout(() => {
                  this.$router.replace({
                    params: {
                      id: upcomingSession.session_id
                    }
                  })
                }, timeDiffInSeconds * 1000)

                this.automaticNotificationTimeoutId = setTimeout(() => {
                  this.showAutomaticNotification = true;
                  this.automaticNotificationMessage = "You will be soon redirected to join the new session";
                },(timeDiffInSeconds > 10 ? (timeDiffInSeconds - 10) : 0)  * 1000)
              }
            } else {
              // set next session immediately
              this.upcomingSession = upcomingSession;
              this.showUpcomingSessionDialog = true;
              this.upcomingSessionTimeoutId = null;
            }
          }
        });
    },
    getSessionMessages() {
      axios
        .get("/session/message_list", {
          params: {
            session_id: this.$route.params.id,
          },
        })
        .then((data) => {
          this.session_message = data.data.data;
        });
    },
    isCompleted(session){
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return endTime.diff(currentTime, 'seconds') < 0
    },
    isInBetween(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return currentTime.isBetween(startTime, endTime);
    },
    isUpcoming(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return !currentTime.isBetween(startTime, endTime) && !this.isCompleted(session)
    },
    pad(n) {
    return (n < 10) ? ("0" + n) : n;
    },
    setRemaining() {
      let intval = setInterval(()=>{
        let currentTime = this.moment();
        let startTime = this.moment(this.session_data.start_time);
        let duration= this.moment.duration(startTime.diff(currentTime))
        
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes())%60;
        var seconds = parseInt(duration.asSeconds())%60;
        this.remaining_time = this.pad(hours) + ':'+ this.pad(minutes)+':'+ this.pad(seconds)
        if(hours == 0 && minutes == 0 && seconds == 0){
          clearInterval(intval)
          this.session_upcoming = false
        }
      },1000)
    },
    subscribeToChannels(sessionId) {
      console.log("subcribed to channel id:", sessionId);
      this.subscription = this.$ws.socket.getSubscription(
        `session:${sessionId}`
      );
      
      if (!this.subscription) {
        this.subscription = this.$ws.subscribe(
          `session:${sessionId}`
        );
        this.$ws.$on(
          `session:${sessionId}|new_message`,
          this.setSocketMessage
        );
        this.$ws.$on(
          `session:${sessionId}|active_users`,
          this.setActiveUsers
        );
      } else {
        this.$ws.$on(
          `session:${sessionId}|new_message`,
          this.setSocketMessage
        );
        this.$ws.$on(
          `session:${sessionId}|active_users`,
          this.setActiveUsers
        );
      }
    },
    unsubscribeFromChannels(sessionId) {
      console.log("unsubcribed from channel id:", sessionId);

      let subscription = this.$ws.socket.getSubscription(
        `session:${sessionId}`
      );

      if (subscription) {
        subscription.close();

        this.$ws.socket.removeSubscription(`session:${sessionId}`);
      }
    },
    handleJoinUpcomingSessionBtnClick() {
      this.$router.replace({
        params: {
          id: this.upcomingSession.session_id
        }
      })
      this.closeUpcomingSessionDialog();
    },
    closeUpcomingSessionDialog() {
      this.upcomingSessionTimeoutId = null;
      this.upcomingSession = null;
      this.showUpcomingSessionDialog = false;
    }
  },
  created: function () {
    this.removeSession();
    this.getSessionDetails();
  },
  mounted: function () {
    this.getSessionMessages();
    
    let is_autoplay = localStorage.getItem('autoplay')
    
    if(is_autoplay == 'false'){
      this.show_playingbutton = true
    }

    this.subscribeToChannels(this.$route.params.id);
  },
  updated: function () {
    this.$nextTick(function () {});
  },
  beforeDestroy() {
    this.setSession(this.session_data);
  },
  beforeRouteEnter  (to,from,next) {
    if(from.name){
      localStorage.setItem('autoplay',true)
    } else {
      localStorage.setItem('autoplay',false)
    }
    next((vm) => {
     vm.from = from;
    });
  },
  beforeRouteLeave (to, from, next) {
    if (from.params.id) {
      // Remove listeners when component destroy
      this.unsubscribeFromChannels(from.params.id)
    }
    next()
  }
};
</script>
<style>
.v-tooltip__content {
  max-width: 550px !important;
}
</style>
<style scoped>
.disable_click{
  pointer-events:none;
}
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}
.chat-screen {
  overflow-y: auto;
  height: 400px;
  max-height: 400px;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #80bc40 transparent transparent transparent;
}
textarea {
  line-height: 1.2rem !important;
}

.session_message_popup_text {
  font-size: 15px !important;
  line-height: 26px;
  color: rgb(61, 61, 61);
}

.automatic_action_notification {
  padding: 4px;
  font-size: 12px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 10px;
  color: white; transition: 1s;
  background-color: #265d28;
  text-align: center;
  transition: height 2s ease-in;
}
</style>
